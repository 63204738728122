/** ========================================================================
 * Standard Mobile Menu
 * This is the basic mobile menu.
 * ======================================================================== */
(function( $ ) {
    /* Mobile Menu */

    // Move parent into sub menu
    if($('#mobileNav .nav .movedParent').length === 0) {
        $("#mobileNav .nav li.page_item_has_children > a, #mobileNav .nav li.menu-item-has-children > a").each( function() {
            var where = $(this).parents('li').children('ul').children('li:first-child');
            $(this).clone().insertBefore(where).wrap('<li class="movedParent"></li>');
        });
        $('#mobileNav .children, #mobileNav .sub-menu').addClass('bgSecondary');
    }

    // Add class to slide content
    $('#mobileNavBtn').click(function() {
        $('body').addClass('mobileOpen');

        var selScrollable = '#mobileNav';
// Uses document because document will be topmost level in bubbling
        $(document).on('touchmove',function(e){
            e.preventDefault();
        });
// Uses body because jQuery on events are called off of the element they are
// added to, so bubbling would not work if we used document instead.
        $('body').on('touchstart', selScrollable, function(e) {
            if (e.currentTarget.scrollTop === 0) {
                e.currentTarget.scrollTop = 1;
            } else if (e.currentTarget.scrollHeight === e.currentTarget.scrollTop + e.currentTarget.offsetHeight) {
                e.currentTarget.scrollTop -= 1;
            }
        });
// Stops preventDefault from being called on document if it sees a scrollable div
        $('body').on('touchmove', selScrollable, function(e) {
            e.stopPropagation();
        });
    });

    // Expand sub menu
    $('#mobileNav .menu-item-has-children > a').on('click', function(d) {
        d.preventDefault();
        $(this).toggleClass('open');
        $(this).next().slideToggle();
    });

    // Overlay
    $('#mobileOverlay').click(function() {
        $('body').removeClass('mobileOpen');
        $(document).unbind('touchmove');
    });
})( jQuery );
